.stake-container {
  .stake-connect-wallet {
    margin-top: 64px;
    width: calc(100% - 32px);
    max-width: 366px;
    height: 48px !important;
    font-weight: 600 !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stake-wallet-desc {
    font-family: IBM Plex Mono, serif;
    font-size: 14px;
    line-height: 130%;
    color: #7f8a94;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }
}