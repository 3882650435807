.monastery-home-page {
  .documentation-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    width: 100%;
    padding-inline-start: 16px;
    position: absolute;
    z-index: 10;
    @media (min-width: 768px) {
      padding-inline-start: 168px;
    }
  }
  .header {
    height: calc(100vh - 142px);
    background: #1f1f1f;
    position: relative;
    overflow: hidden;
    font-family: Bebas Neue, serif;
    .hero {
      position: absolute;
      width: auto;
      height: calc(100% - 30px);
      right: 0;
      top: 30px;
      object-fit: cover;
    }
    .title-container {
      background: #1f1f1f;
      height: 100%;
      width: fit-content;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin: 32px auto;
      @media (min-width: 768px) {
        margin-inline-start: 168px;
        align-items: flex-start;
        margin-top: 0;
        margin-bottom: 0;
        //margin: 0 auto;
      }
      .header-title {
        white-space: normal;
        margin: 0;
        font-weight: bold;
        font-size: 38px;
        color: #ffffff;
        letter-spacing: 4px;
        z-index: 1000;
        @media (min-width: 768px) {
          font-size: 56px;
        }
      }
      .header-desc {
        margin-top: 16px;
        max-width: 575px;
        text-align: justify;
        color: #ffffff;
        font-family: IBM Plex Mono, serif;
        z-index: 10;
        font-size: 18px;
        padding: 16px;
        @media (min-width: 768px) {
          padding: 0;
        }
      }
      .header-buttons {
        margin-top: 16px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (min-width: 768px) {
          justify-content: flex-start;
        }
      }
    }
  }
}
