.use-case-layout {
  display: flex;
  background-color: #1f1f1f;
  .use-case-child {
    margin: 88px auto 0 auto;
    padding: 16px;
    width: 100%;
    min-height: calc(100vh - 88px);
    //p, span, strong {
    //  color: #FFFFFF;
    //}
  }
  .use-case-max-width {
    max-width: 1100px;
  }
  .coming-soon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    //height: 100%;
    position: relative;
    top: 0;
    left: 0;
    z-index: 8;
    p {
      font-family: Bebas Neue, serif;
      font-size: 64px;
      line-height: 72px;
      margin-bottom: 0;
      letter-spacing: 4px;
      color: white;
      font-weight: bold;
      @media (min-width: 560px) {
        font-size: 112px;
        letter-spacing: 8px;
      }
      @media (min-width: 1440px) {
        margin-left: 300px;
      }
    }
  }
}
