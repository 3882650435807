.content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  font-family: IBM Plex Mono, serif;
  font-size: 14px;
  line-height: 20px;
  text-align: start;
  margin-bottom: 24px;
  .content-row-title {
    max-width: 110px;
    @media (min-width: 576px) {
      max-width: unset;
    }
  }
  @media (min-width: 576px) {
    font-size: 16px;
  }
}