.ms-contained-button {
  background: #E6F300 !important;
  box-shadow:
    0 7px 12px rgba(0, 0, 0, 0.0392157),
    0 13px 14px rgba(0, 0, 0, 0.0784314),
    0 20px 15px rgba(0, 0, 0, 0.0392157),
    0 2px 4px rgba(0, 0, 0, 0.501961);
  border-radius: 4px !important;
  height: 52px !important;
  border: 1px solid #E6F300 !important;
  width: fit-content;
  min-width: 143px;
  color: #141517 !important;
  font-size: 16px !important;
  font-family: IBM Plex Mono, serif;
  font-weight: 600 !important;
  &:hover {
    border-color: #F3A100 !important;
    background-color: #F3A100 !important;
    color: #141517 !important;
  }
}