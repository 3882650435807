.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .ant-spin {
    .ant-spin-dot {
      font-size: 56px;
    }
    .ant-spin-dot-item {
      background: #E6F300;
      width: 24px;
      height: 24px;
    }
  }
  @media (min-width: 1440px) {
    padding-left: 300px;
  }
}