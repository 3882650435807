.text-input-container {
  .text-input-label {
    color: #ffffff;
    font-weight: 500;
    font-family: IBM Plex Mono, serif;
    line-height: 18.2px;
    text-align: justify;
  }
  .text-input-wrapper {
    width: 100%;
    height: 48px;
    position: relative;

    .input-end-action {
      position: absolute;
      right: 8px;
      top: 8px;
      z-index: 10;
      font-family: IBM Plex Mono, serif;
      height: 32px;
      min-width: 68px;
      font-weight: bold;
      line-height: 20px;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      border: 0;
      background: transparent;
      color: #ffffff;
      transition: all 50ms linear;
      border-radius: 4px;
      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }
    }
    span {
      position: absolute;
      color: #ffffff;
      font-family: IBM Plex Mono, serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      right: 14px;
      top: 14px;
      z-index: 10;
    }

    .text-input {
      width: 100%;
      height: 48px;
      background-color: rgb(31, 31, 31) !important;
      font-family: IBM Plex Mono, serif !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #ffffff !important;
      border-radius: 4px !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.8) !important;
      }

      &:focus {
        border: 2px solid #e6f300 !important;
        box-shadow: none !important;
      }
    }
  }
}
