.ant-modal-content,
.ant-modal-header {
  background: #262626 !important;
  box-shadow: 0 7px 12px rgba(0, 0, 0, 0.0392157), 0 13px 14px rgba(0, 0, 0, 0.0784314),
    0 20px 15px rgba(0, 0, 0, 0.0392157), 0 2px 4px rgba(0, 0, 0, 0.501961);
  border-radius: 15px !important;
}

.ant-modal-title {
  color: #fff !important;
  font-family: Bebas Neue, serif;
  font-size: 32px !important;
  line-height: 100% !important;
}

.ant-table-tbody > tr > td {
  border: none !important;
}

.ant-table-thead > tr {
  border: none !important;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  justify-content: center;
}

/* .ant-checkbox {
  background: #1f1f1f !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
} */

.ant-checkbox-inner {
  background: #1f1f1f !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
}

.note-text {
  font-family: IBM Plex Mono, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #7f8a94 !important;
  margin-top: 16px;
}

.ant-modal-close-x {
  color: #7f8a94 !important;
}

.tv1-box-values {
  font-family: IBM Plex Mono, serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.ant-tabs-nav {
  margin-top: 16px !important;
}

.claim-max-note {
  font-family: IBM Plex Mono, serif;
  color: #7f8a94;
  font-size: 18px;
}

.ant-checkbox-wrapper {
  margin-bottom: 10px !important;
  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    &::after {
      top: 49% !important;
      left: 24.5% !important;
      width: 7.714286px !important;
      height: 13.142857px !important;
    }
  }
  .ant-checkbox + span {
    position: absolute;
    left: 24px;
    top: 4px;
    white-space: nowrap;
  }
  span {
    color: #ffffff;
    font-family: IBM Plex Mono, serif;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #e6f300 !important;
    border-color: #e6f300 !important;
  }
}

.max-input {
  right: 140px !important;
  top: 10px !important;
}

@media only screen and (max-width: 600px) {
  .tvl-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .tvl-box-values {
    font-size: 18px !important;
  }
  .tvl-box-title {
    font-size: 18px !important;
    margin-top: 5px;
  }
  .table-wrapper {
    padding: 0;
    margin: 20px 12px;
  }
}

.ant-modal-wrap {
  overflow: hidden;
  .ant-modal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 48px !important;
    max-width: calc(100% - 16px) !important;
    @media (min-width: 992px) {
      max-width: 992px !important;
    }
    .ant-modal-header {
      border-bottom: 2px solid #1f1f1f !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px 15px 0 0 !important;
      box-shadow: none !important;
      height: 80px;
    }
    .ant-modal-content {
      margin-bottom: 64px;
      width: 100%;
      padding: 0 12px;
      //max-height: calc(86vh - 48px);
      //overflow-y: hidden;
    }
    .ant-modal-body {
      @media (max-width: 576px) {
        padding: 0 !important;
      }
    }
  }
}

.input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
  }
  .stake-button {
    width: 100%;
    height: 48px !important;
    margin: 16px auto;
    @media (min-width: 576px) {
      margin: 0;
      max-width: 130px !important;
    }
  }
  .text-input-container {
    width: 100% !important;
    margin-bottom: 0;
    @media (min-width: 576px) {
      width: calc(100% - 164px);
      margin-right: 20px;
    }
  }
}
.bond-redeem-button {
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}
