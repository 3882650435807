.ant-modal {
  max-width: calc(100% - 16px) !important;
}
.no-metamask-modal {
  .ant-modal-content {
    min-height: 180px;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  p {
    font-size: 18px;
    color: #FFFFFF;
    font-family: IBM Plex Mono, serif;
    font-weight: 700;
    max-width: 100%;
    white-space: normal;
  }
  a {
    font-size: 18px;
    font-family: IBM Plex Mono, serif;
    font-weight: 700;
    color: #E6F300;
    &:hover {
      color: #F3A100;
    }
  }
}