@font-face {
  font-family: Bebas Neue;
  src: url('./assets/fonts/BebasNeue-Regular.ttf');
}
@font-face {
  font-family: IBM Plex Mono;
  src: url('./assets/fonts/IBMPlexMono-Regular.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto.ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overflow-y: overlay;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    position: absolute;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }
}

* {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
    opacity: 0.2;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-corner {
    width: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-scrolling-effect {
  overflow: hidden !important;
  width: 100% !important;
}

.ant-tabs-tab {
  &:hover {
    color: #e6f300 !important;
  }
}

.ant-notification-notice-message {
  color: #e6f300 !important;
  font-size: 20px !important;
  font-family: Bebas Neue, serif;
  letter-spacing: 1px;
  padding-top: 2px;
  margin-bottom: 8px;
}
.ant-notification-notice-close {
  color: white !important;
  font-size: 18px !important;
}