.dashboard-statistics-row {
  .dashboard-statistics-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 18px !important;
    margin: 15px 0;
    @media (min-width: 768px) {
      align-items: center;
    }
    .statistic-col-label {
      span {
        font-family: Roboto, serif !important;
        font-size: 18px;
      }
      font-family: Bebas Neue, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 100%;
      text-align: justify;
      letter-spacing: 1px;
      color: #7f8a94;
      margin: 0;
    }
    .statistic-col-value {
      font-family: IBM Plex Mono, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      text-align: justify;
      letter-spacing: 1px;
      color: #ffffff;
      text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
      margin: 0;
      white-space: nowrap;
    }
    @media (min-width: 576px) {
      .statistic-col-label {
        font-size: 18px;
      }
      .statistic-col-value {
        font-size: 20px;
      }
    }
    @media (min-width: 992px) {
      .statistic-col-label {
        font-size: 20px;
      }
      .statistic-col-value {
        font-size: 26px;
      }
    }
  }
}