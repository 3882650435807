.monastery-home-page {
  .stake-section {
    width: 100%;
    background: #1f1f1f;
    position: relative;
    .staking-right-img {
      position: absolute;
      top: 50%;
      right: 0;
      height: 50%;
      max-height: 50%;
      object-fit: cover;
      @media (max-width: 992px) {
        display: none;
      }
    }
    .staking-left-img {
      position: absolute;
      left: 0;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      max-width: 320px;
      @media (max-width: 992px) {
        display: none;
      }
    }
    .stake-container {
      padding: 64px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 1200px;
      margin: 0 auto;
      color: #ffffff;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .stake-section-left {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (min-width: 768px) {
          align-items: flex-start;
        }
        .stake-number {
          font-family: IBM Plex Mono, serif;
          font-weight: 600;
          font-size: 60px;
          line-height: 60px;
          color: #e6f300;
          text-shadow: 2px 2px 1px #18191b;
          margin-bottom: 16px;
          z-index: 10;
        }
        .stake-number-sub-text {
          font-family: Bebas Neue, serif;
          font-size: 20px;
          line-height: 20px;
          color: #7f8a94;
          z-index: 10;
        }
      }
      .stake-section-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 32px;
        @media (min-width: 768px) {
          margin-top: 0;
          margin-inline-start: 64px;
        }
        .stake-title {
          font-family: IBM Plex Mono, serif;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
          text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
          text-align: center;
          margin: 16px auto;
          z-index: 10;
          @media (min-width: 768px) {
            margin: 18px 0;
          }
        }
        .stake-sub-title {
          font-family: IBM Plex Mono, serif;
          font-size: 18px;
          line-height: 27px;
          color: #7f8a94;
          z-index: 10;
        }
        .stake-text {
          font-size: 18px;
          line-height: 27px;
          max-width: 585px;
          text-align: justify;
          font-family: IBM Plex Mono, serif;
          color: #ffffff;
          z-index: 10;
          @media (min-width: 768px) {
            text-align: left;
          }
        }
      }
    }
  }
}
