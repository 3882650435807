.apy-box {
  margin-top: 20px;
  font-family: Bebas Neue, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  text-align: justify;
  letter-spacing: 1px;
  color: #7f8a94;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-inline-start: 16px;
  flex-direction: column;
  @media (min-width: 576px) {
    align-items: center;
    padding-inline-start: 0;
  }
  .apy-number {
    font-family: IBM Plex Mono, serif;
    font-weight: bold;
    font-size: 26px;
    line-height: 130%;
    text-align: justify;
    letter-spacing: 1px;
    color: #e6f300;
    text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
  }
}