.dashboard-container {
  .chart-title-value {
    font-family: IBM Plex Mono, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    text-align: justify;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
    @media (min-width: 992px) {
      font-size: 26px;
    }
  }
}
.coming-soon {
  font-size: 64px;
  color: white;
  letter-spacing: 2px;
  font-family: Bebas Neue, serif;
  @media (min-width: 600px) {
    font-size: 108px;
  }
}