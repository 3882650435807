.monastery-home-page {
  .footer-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 64px 16px 32px 16px;
    @media (max-width: 992px) {
      .footer-col-1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
      }
      .footer-col-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media (max-width: 576px) {
          margin-top: 64px;
        }
      }
      .footer-col-3,
      .footer-col-4 {
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .footer-title {
      font-family: Bebas Neue, serif;
      font-size: 24px;
      text-align: start;
      text-transform: uppercase;
      color: #7f8a94;
      margin-bottom: 16px;
    }

    .footer-sub-menu {
      font-family: IBM Plex Mono, serif;
      font-weight: 500;
      font-size: 18px;
      color: #0e0e0e;
      margin: 8px 0;
      cursor: pointer;
      text-align: start;
      display: block;
    }
    .social-logos {
      display: flex;
      justify-content: flex-start;
      .landing-social-icon {
        cursor: pointer;
        svg {
          margin-inline-end: 16px;
        }
        &:hover {
          svg {
            path {
              fill: #f3a100;
            }
          }
        }
      }
    }
    .footer-copy-right {
      display: flex;
      justify-content: center;
      font-family: IBM Plex Mono, serif;
      text-align: center;
      color: #444444;
      margin-top: 64px;
    }
  }
}
