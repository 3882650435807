.ms-outlined-button {
  background: transparent !important;
  color: #e6f300 !important;
  border: 1px solid #e6f300 !important;
  border-radius: 4px !important;
  height: 50px !important;
  min-width: 181px;
  font-size: 16px !important;
  font-family: IBM Plex Mono, serif;
  font-weight: 600;
  &:hover {
    border: 1px solid #F3A100 !important;
    background: transparent !important;
    color: #F3A100 !important;
  }
}