.calculator-page {
  .calculator-form {
    padding: 36px;
    .range-slider-box {
      background: #181818;
      border: 1px dashed rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 4px;
      color: #fff;
      display: flex !important;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
    }
  }
}