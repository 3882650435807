.ant-tabs-ink-bar {
  height: 5px !important;
  background: #e6f300 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  font-family: IBM Plex Mono, serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: justify;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff !important;
}

.ant-tabs-tab {
  font-family: IBM Plex Mono, serif;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  text-align: justify;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #888888;
  text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
  @media (max-width: 400px) {
    div {
      font-size: 12px;
    }
  }
  @media (min-width: 768px) {
    div {
      font-size: 20px;
    }
  }
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 16px !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  //margin: 0 0 0 32px !important;
}

.ant-tabs-nav-list {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  justify-content: center;
  height: 42px;
  @media (min-width: 576px) {
    height: 64px;
  }
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.content-row {
  display: flex;
  justify-content: space-between;
}

.ant-tabs-content {
  padding: 30px;
  color: #fff;
  justify-content: center;
}

.tab-one-text-content {
  margin-top: 31px;
  font-family: IBM Plex Mono, serif;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;
  color: #ffffff;
}

.tab-one-text-content .value-section {
  font-weight: bold;
}

.input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  .stake-switch {
    position: absolute;
    bottom: 100%;
    left: 0;
  }
  @media (min-width: 576px) {
    flex-direction: row;
  }
  .stake-button {
    width: 100%;
    height: 48px !important;
    margin: 16px auto;
    @media (min-width: 576px) {
      margin: 0;
      max-width: 130px !important;
    }
  }
  .text-input-wrapper {
    width: 100% !important;
    margin-bottom: 0;
    @media (min-width: 576px) {
      width: calc(100% - 164px);
      margin-right: 20px;
    }
  }
}

.yellow-text {
  color: #e6f300 !important;
}

.boost-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #181818;
  border: 1px dashed #e6f300;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  //margin-top: 30px;
  .stake-monk-button {
    width: 100%;
    max-width: 430px;
  }
  .boost-box-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    span {
      font-size: 14px;
    }
    @media (min-width: 768px) {
      justify-content: flex-start;
      margin-bottom: 0;
      span {
        font-size: 16px;
      }
    }
  }
  .boost-box-text {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: IBM Plex Mono, serif;
      margin: 0 16px;
      font-size: 18px;
      @media (min-width: 576px) {
        font-size: 22px;
      }
      @media (min-width: 992px) {
        font-size: 26px;
      }
    }
    span {
      font-family: IBM Plex Mono, serif;
      font-weight: 700;
      padding-right: 16px;
      font-size: 18px;
      @media (min-width: 576px) {
        font-size: 22px;
      }
      @media (min-width: 992px) {
        font-size: 26px;
      }
    }
  }
  @media (min-width: 576px) {
    flex-direction: row;
  }
}
