.bond-page {
  .bond-main {
    margin: 16px;
    .bond-main-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 16px 0;
      @media (min-width: 768px) {
        align-items: center;
        margin: 0;
      }
    }
    .bond-main-title {
      font-family: Bebas Neue, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 100%;
      text-align: justify;
      letter-spacing: 1px;
      color: #7f8a94;
      margin: 0;
    }
    .bond-main-values {
      font-family: IBM Plex Mono, serif;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 130%;
      text-align: justify;
      letter-spacing: 1px;
      color: #ffffff;
      text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
      margin: 0;
      @media (min-width: 768px) {
        font-size: 26px;
      }
    }
  }
  .bond-main-divider {
    width: 100%;
    height: 1px;
    border: 0;
    margin-top: 0;
    margin-bottom: 6px;
    background-color: #1f1f1f;
  }
}