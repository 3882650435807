.monastery-home-page {
  .how-it-works-section {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 32px 16px;
    margin: 0 auto;
    @media (min-width: 768px) {
      padding: 64px 16px;
    }
    .how-it-works-title {
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #0e0e0e;
      font-family: Bebas Neue, serif;
      font-size: 25px;
      line-height: 70px;
      margin: 0;
      @media (min-width: 768px) {
        font-size: 64px;
      }
    }
    .how-it-works-subtitle {
      margin-top: 16px;
      font-family: IBM Plex Mono, serif;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: #444444;
      max-width: 708px;
    }
    .how-works-row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 32px 16px;
      @media (min-width: 768px) {
        flex-direction: row;
        margin: 64px 16px;
      }
      .how-works-text-container {
        display: flex;
        align-items: self-start;
        justify-content: flex-start;
        .counter-text {
          font-family: Bebas Neue, serif;
          font-size: 76px;
          line-height: 100%;
        }
        .how-works-text-box {
          margin-inline-start: 16px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          .how-works-text-title {
            font-family: Bebas Neue, serif;
            font-size: 42px;
            line-height: 46px;
            text-align: left;
            text-transform: uppercase;
            color: #0e0e0e;
            margin-bottom: 8px;
            font-weight: 400;
          }
          .how-works-text-subtitle {
            font-family: IBM Plex Mono, serif;
            text-align: justify;
            max-width: 482px;
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
            @media (min-width: 768px) {
              text-align: left;
            }
          }
        }
      }
      .how-works-second {
        padding-top: 32px;
        //width: 100%;
        @media (min-width: 768px) {
          padding-inline-start: 16px;
          padding-top: 0;
          //width: 50%;
        }
      }
      .how-works-first {
        padding-top: 32px;
        //width: 100%;
        @media (min-width: 768px) {
          padding-inline-end: 16px;
          padding-top: 0;
          //width: 50%;
        }
      }
      .how-works-img {
        width: 100%;
        max-width: 360px;
      }
    }
  }
}