.monastery-home-page {
  .intro-section {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 48px 16px;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .intro-main-text {
      font-family: Bebas Neue, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #0e0e0e;
      text-align: center;
      line-height: 110%;
      @media (min-width: 768px) {
        font-size: 56px;
        text-align: left;
      }
    }
    .intro-sub-text {
      font-family: IBM Plex Mono, serif;
      font-size: 18px !important;
      color: #444444;
      text-align: justify;
      margin-top: 16px;
      max-width: 570px;
      @media (min-width: 768px) {
        text-align: left;
        margin: 0;
      }
    }
    .intro-buttons-container {
      margin: 16px auto 32px auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
      @media (min-width: 800px) {
        flex-direction: row;
        margin: 32px 0 0 0;
      }
    }
    .intro-section-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .intro-section-img {
      max-width: 100%;
    }
  }
}
