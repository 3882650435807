.stake-container {
  .stake-header {
    padding-left: 36px;
    padding-top: 24px;
    .stake-title {
      text-align: left;
      font-family: Bebas Neue, serif;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff !important;
      text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
      margin-bottom: 0;
      @media (min-width: 576px) {
        font-size: 32px;
      }
    }
    .stake-subtitle {
      font-family: IBM Plex Mono, serif;
      font-size: 14px;
      line-height: 130%;
      text-align: justify;
      color: #7f8a94;
    }
  }
  .stake-header-divider {
    width: 100%;
    height: 1px;
    border: 0;
    margin-top: 6px;
    margin-bottom: 6px;
    background-color: #1f1f1f;
  }
}