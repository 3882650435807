.sidebar {
  .sidebar-open-btn {
    width: 50px;
    min-width: 50px;
    height: 50px !important;
    padding: 0;
    position: fixed;
    top: 20px;
    z-index: 10;
  }
}
.sidebar-wrapper {
  position: sticky;
  left: 0;
  top: 0;
  height: 100%;
  max-height: 100vh;
  background: #181818;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: justify;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #7f8a94;
  text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
  z-index: 100;
  .side-bar-social-icon {
    cursor: pointer;
    svg {
      path {
        fill: #7f8a94 !important;
      }
    }
    &:hover {
      svg {
        path {
          fill: white !important;
        }
      }
    }
  }
  .sidebar-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 66px;
    :hover {
      cursor: pointer;
    }
  }

  .ant-menu {
    background: #181818 !important;
  }
  .ant-menu-item {
    background: #181818 !important;
    color: #7f8a94;
    text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
  }

  .ant-menu-item:hover {
    //color: #ccc !important;
  }

  .ant-menu-item-disabled {
    cursor: auto;
    .ant-menu-title-content {
      color: #7f8a94;
      opacity: 0.2;
    }
  }

  .ant-menu-item-selected {
    color: #fff !important;
    background: rgba(87, 97, 112, 0.08) !important;
  }
  .ant-menu-item-active {
    color: #e6f300 !important;
  }

  .ant-menu-inline {
    width: calc(100% - 1px) !important;
  }

  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
  }

  @media only screen and (max-width: 360px) {
    .sidebar-wrapper {
      width: 252px;
    }
  }

  @media only screen and (max-width: 768px) {
    .sidebar-wrapper {
      height: 100% !important;
    }
  }
}
