.monastery-home-page {
  .statistics-section {
    min-height: 142px;
    width: 100%;
    background: #0e0e0e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .statistics-section-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0;
      @media (min-width: 768px) {
        margin: 0;
      }
      p {
        margin-bottom: 0;
      }
    }
    .statistics-section-title {
      font-family: Bebas Neue, serif;
      text-align: center;
      font-size: 20px;
      letter-spacing: 1px;
      color: #7f8a94;
    }

    .statistics-section-value {
      font-family: IBM Plex Mono, serif;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }
}