.bond-page {
  .bond-header {
    padding-top: 24px;
    padding-left: 36px;
    .bond-header-title {
      text-align: left;
      font-family: Bebas Neue, serif;
      font-size: 32px;
      line-height: 110%;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #ffffff !important;
      text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
    }
  }
  .bond-header-divider {
    width: 100%;
    height: 1px;
    border: 0;
    margin-top: 16px;
    margin-bottom: 6px;
    background-color: #1f1f1f;
  }
}