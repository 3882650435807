.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #1f1f1f;
  position: fixed;
  padding: 20px 0;
  top: 0;
  right: 0;
  z-index: 2;
  @media only screen and (max-width: 540px) {
    .hide-button {
      display: none !important;
    }
  }
  .navbar-back-icon {
    display: flex;
    position: absolute;
    left: 24px;
    top: 82px;
    background: #1f1f1f;
    @media (min-width: 768px) {
      position: relative;
      left: unset;
      top: unset;
      display: flex;
      margin-inline-end: auto;
      margin-inline-start: 95px;
    }
    @media (min-width: 1440px) {
      display: flex;
      margin-inline-end: auto;
      margin-inline-start: 325px;
    }
  }
}
body {
  .ant-dropdown-menu {
    padding: 0 !important;
    overflow: hidden;
  }
  .navbar-menu {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    margin-left: -45px;
    .navbar-menu-item {
      margin: 0;
      color: whitesmoke;
      padding: 16px 24px;
      font-size: 16px;
      text-align: left;
    }
    .ant-dropdown-menu-item {
      padding: 0;
    }
    .ant-dropdown-menu-item-active {
      color: white;
      background: #262626;
    }
  }
}