.monastery-home-page {
  .learn-more-section {
    width: 100%;
    background: #1f1f1f;
    .learn-more-container {
      max-width: 1200px;
      width: 100%;
      padding: 64px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      .learn-more-img {
        width: 100%;
        max-width: 400px;
      }
      .learn-more-right-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 32px;
        @media (min-width: 768px) {
          padding-left: 20px;
          margin-top: 0;
          align-items: flex-start;
        }
        .learn-more-main-text {
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #ffffff;
          font-family: Bebas Neue, serif;
          font-size: 32px;
          line-height: 110%;
          text-align: center;
          margin-bottom: 20px;
          @media (min-width: 768px) {
            font-size: 56px;
            text-align: left;
          }
        }
        .learn-more-sub-text {
          font-family: IBM Plex Mono, serif;
          max-width: 570px;
          color: #FFFFFF;
          text-align: justify;
          margin-bottom: 32px;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
}