.bond-page {
  .content-card {
    overflow: hidden;
  }
  .bond-table-title {
    font-family: Bebas Neue, serif;
    font-size: 18px;
    line-height: 100%;
    text-align: start;
    letter-spacing: 1px;
    color: #7f8a94;
    margin: 0;
    @media (min-width: 576px) {
      text-align: center;
    }
  }
  .bond-table-values {
    font-family: IBM Plex Mono, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    text-align: start;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: 2px 2px 1px rgba(24, 25, 27, 0.6);
    margin: 0;
    @media (min-width: 576px) {
      text-align: center;
    }
    &__disable {
      text-decoration: line-through;
    }
  }
  .bond-table {
    margin: 16px 0;
    .ant-table {
      background: #262626 !important;
      color: #fff !important;
    }
    .bond-table-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 220px;
      .ant-spin-dot {
        width: 64px;
        font-size: 64px;
      }
      .ant-spin-dot-item {
        width: 24px;
        height: 24px;
        background-color: #e6f300;
      }
    }

    .ant-table-thead {
      th {
        border-bottom: 0;
      }
      background: #262626 !important;
    }
    .ant-table-cell {
      background: inherit !important;
      text-align: center;
      @media (min-width: 900px) {
        min-width: 150px;
      }
    }
    .ant-table-cell-row-hover {
      background: #262626 !important;
    }
    .ant-empty-description {
      font-family: IBM Plex Mono, serif;
      color: #fff !important;
      margin-top: 16px;
    }

    .table-wrapper {
      padding: 25px 36px 16px 36px;
    }

    .ant-table-thead > tr > th {
      background: #262626 !important;
      color: #fff !important;
    }

    .ant-modal-content,
    .ant-modal-header {
      background: #262626 !important;
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.0392157), 0 13px 14px rgba(0, 0, 0, 0.0784314),
        0 20px 15px rgba(0, 0, 0, 0.0392157), 0 2px 4px rgba(0, 0, 0, 0.501961);
      border-radius: 15px !important;
    }

    .ant-modal-title {
      color: #fff !important;
      font-family: Bebas Neue, serif;
      font-size: 32px !important;
      line-height: 100% !important;
    }

    .input-row {
      display: flex !important;
    }

    .ant-table-tbody > tr > td {
      border: none !important;
    }

    .ant-table-thead > tr {
      border: none !important;
    }

    .input-row .ant-input {
      background: #1f1f1f;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      margin-inline-end: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      justify-content: center;
    }

    /* .ant-checkbox {
      background: #1f1f1f !important;
      border: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-radius: 4px !important;
    } */
  }
}
.line-through {
  text-decoration: line-through;
}
