.content-card {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #262626;
  box-shadow:
    0 7px 12px rgba(0, 0, 0, 0.0392157),
    0 13px 14px rgba(0, 0, 0, 0.0784314),
    0 20px 15px rgba(0, 0, 0, 0.0392157),
    0 2px 4px rgba(0, 0, 0, 0.501961);
  border-radius: 15px;
  margin: 0 auto;
  .content-card-title {
    font-family: Bebas Neue, serif;
    font-size: 20px;
    line-height: 100%;
    text-align: justify;
    letter-spacing: 1px;
    color: #7f8a94;
  }
}
.content-card-max-width {
  max-width: 1004px;
}